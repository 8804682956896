export default {
    methods: {
        setRouteParams(params, row) {
            let setParams = {};
            for (let [key, value] of Object.entries(params)) {
                if (typeof row[value] != 'undefined') {
                    setParams[key] = row[value];
                } else {
                    setParams[key] = value;
                }
            }
            return setParams;
        },
    },
}
