<template>
  <div @dblclick="forceDisabled() ? $emit('copyValue', modelValue):''">
    <beekman-date-picker
        ref="calendar"
        v-model="proxySelected"
        :attributes="attributes"
        :locale="this.config.locale ? this.config.locale : 'nl'"
        :max-date="max"
        :min-date="min"
        :is-dark="darkMode"
        :input-debounce="500"
        :model-config="modelConfig"
        :masks="masks"
        :popover="{ positionFixed: true, visibility: 'hidden' }"
        show-iso-weeknumbers
        trim-weeks>
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="flex shadow-sm" @click="forceDisabled() ? false : togglePopover()">
          <input :class="classes"
                 ref="input"
                 :readonly="forceDisabled()"
                 :placeholder="placeholder"
                 :tabindex="index"
                 :value="inputValue"
                 class="date"
                 type="text"
                 v-on="inputEvents"
          />
          <div :disabled="forceDisabled()"
               class="px-2 rounded-r text-xs bg-gray-200 flex items-center justify-center" :class="{'cursor-pointer': !forceDisabled()}">
            <i class="fal fa-calendar-alt"></i>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div v-if="$refs.calendar && modelValue !== today" class="bg-gray-100 text-center p-2 border-t rounded-b-lg">
          <button class="bg-blue-500 text-white font-medium px-2 py-1 rounded hover:bg-blue-600"
                  @click="moveToToday">
            Vandaag
          </button>
        </div>
      </template>
    </beekman-date-picker>
  </div>
</template>
<script>
import {DatePicker as BeekmanDatePicker} from 'v-calendar';
import BeekmanFocusField from "@ui/Mixins/FocusField.js";
import moment from 'moment';
import { mapState } from 'pinia';
import { userStore } from '@ui/Stores/User.js';

export default {
  emits: ['update:modelValue', 'copyValue'],
  props: {
    id: String,
    modelValue: String,
    isValid: [Boolean, Number],
    config: [Array, Object],
    reference: String,
    type: String,
    disabled: [Boolean, Number],
    index: [Number, String],
    data: [Array, Object],
  },
  components: {
    BeekmanDatePicker,
  },
  mixins: [
    BeekmanFocusField,
  ],
  data() {
    const dateFormat = this.config.format ? this.config.format : 'DD-MM-YYYY';
    const today = moment().format(dateFormat);
    return {
      today,
      attributes: [
        {
          key: "today",
          dot: true,
          dates: new Date(),
        },
      ],
      modelConfig: {
        type: "string",
        mask: dateFormat,
      },
      masks: {
        input: dateFormat,
        weekdays: 'WW',
      },
    };
  },
  methods: {
    moveToToday() {
      if (this.$refs.calendar) {
        this.$refs.calendar.move(this.today);
        this.$emit('update:modelValue', this.today);
      }
    },
    forceDisabled() {
      if (this.config) {
        if (this.config.disabled) {
          return this.config.disabled;
        }
      }
      return this.disabled;
    },
  },
  computed: {
    ...mapState(userStore, ['darkMode']),
    proxySelected: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    placeholder() {
      return '';
    },
    min() {
      return this.config.min ? this.config.min : null;
    },
    max() {
      return this.config.max ? new Date(Date.parse(this.config.max)) : null;
    },
    classes() {
      return this.isValid ? '' : 'invalid'
    }
  },
}
</script>
